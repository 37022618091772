import React from 'react';
import styled from 'styled-components';

import BaseCard from '../../components/BaseCard';
import {Title} from '../../components/typography';
import { Heading1 } from '../../components/typography';

const StyledAuthPage = styled.div`
  position: relative;
  text-align: center;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  color: white;
  background: url('./assets/images/bg.jpeg');
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  .auth-brand-section,
  .auth-form-section {
    flex: 1;
    height: 100%;
    padding: 2% 3%;
  }

  .auth-brand-section {
  }

  .auth-form-section {
  }
`;

export default function AuthPage() {
  return (
    <StyledAuthPage>
      <div className="auth-brand-section">
        <Title>The Future of Social Security</Title>
      </div>

      <div className="auth-form-section">
        <BaseCard radius={28}>
          <h1>hi</h1>
        </BaseCard>
      </div>
    </StyledAuthPage>
  );
}
