import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '../Button';

const StyledNavbar = styled.nav`
  display: flex;
  justify-content: center;
  background-color: black;
  padding: 10px;
  background-color: #040303;
`;

const Container = styled.div`
  position: relative;
  /* width: 1400px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%; //move this later

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 22px;
  opacity: 0.86;

  @media only screen and (max-width: 500px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 12px;
  }
`;

const List = styled.ul`
  display: flex;
  gap: 26px;
  list-style: none;
  align-items: center;
  height: 100%;
`;

const ListItem = styled.li`
  cursor: pointer;
  color: white;
  opacity: 0.6;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
`;

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthPage = location.pathname.includes('/auth');

  const hideNavbar = isAuthPage;

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return !hideNavbar ? (
    <StyledNavbar>
      <Container>
        <Logo src="/assets/images/logo-full.png" />

        <Links>
          <List>
            <ListItem onClick={() => scrollToSection('home-mission')}>
              Mission
            </ListItem>
            <ListItem onClick={() => scrollToSection('home-tokenomics')}>
              Tokenomics
            </ListItem>
            <ListItem onClick={() => scrollToSection('home-learn-more')}>
              Learn More
            </ListItem>
            {/* <Button
              label="Sign up"
              type="button"
              onClick={() => navigate('/auth')}
              bg="#2d8fd2"
            /> */}
          </List>
        </Links>
      </Container>
    </StyledNavbar>
  ) : (
    <></>
  );
};

export default Navbar;
