import React from 'react';
import styled from 'styled-components';

const StyledBaseCard = styled.div<any>`
  background-color: white;
  border-radius: ${(props) => `${props.radius}px` || '10px'};
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)';
  padding: ${(props) => props.padding || '20px'};
  display: ${(props) => props.display || 'block'};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.7) 100%
  );
`;

const BaseCard = ({
  children,
  radius,
  padding,
  display,
  height,
  width,
}: any) => {
  return (
    <StyledBaseCard
      radius={radius}
      padding={padding}
      display={display}
      height={height}
      width={width}
    >
      {children}
    </StyledBaseCard>
  );
};

export default BaseCard;
