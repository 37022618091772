import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  bg?: string;
}

const StyledButton = styled.button<{ bg?: string; disabled: boolean }>`
  background-color: ${(props) => props.bg || '#05c3e5'};
  color: white;
  font-weight: 500;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  span {
    font-weight: 700;
  }
`;

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  disabled = false,
  type = 'submit',
  bg = '#05c3e5',
}) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} type={type} bg={bg}>
      <span>{label}</span>
    </StyledButton>
  );
};

export default Button;
