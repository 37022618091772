import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import BearModel from './BearModel';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../../components/typography';

const Section = styled.section`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 6%; //move this later
`;

const Container = styled.div`
  height: 100%;
  scroll-snap-align: center;
  display: flex;
  justify-content: space-between;
  position: relative; // Keep relative positioning for absolute children

  @media only screen and (max-width: 900px) {
    flex-direction: column; // Stack components on small screens
    align-items: center; // Center content if desired
  }
`;

const Left = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
  text-align: left;
  position: relative; // Ensure text is above the 3D model

  @media only screen and (max-width: 900px) {
    flex: 1;
    align-items: center;
    text-align: center;
    transform: translate(0, -5%);
    z-index: 10; // Higher z-index to stay above the model
  }
`;

const Subtitle = styled.h2`
  color: #2d8fd2;
  margin: 0;
  opacity: 0.96;

  @media only screen and (max-width: 900px) {
    font-size: 22px;
  }
`;

const Right = styled.div`
  flex: 2;
  position: relative; // Maintain relative positioning

  @media only screen and (max-width: 900px) {
    position: absolute; // Set to absolute positioning on smaller screens
    top: 50%; // Move below the Left component
    left: 50%;
    transform: translate(-50%, -50%); // Center horizontally
    height: 800px; // Set height or remove as needed
    z-index: 1; 
    opacity:0.5;
  }
`;

const Hero = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <Left>
          <Title>{t('home.hero.title')}</Title>
          <Subtitle>- Save, Build, Achieve</Subtitle>
        </Left>
        <Right>
          <Canvas className="hero-canvas">
            <Suspense fallback={null}>
              <OrbitControls enableZoom={false} />
              <ambientLight intensity={1} />
              <directionalLight position={[3, 2, 1]} />
              <Environment preset="city" />
              <BearModel />
            </Suspense>
          </Canvas>
        </Right>
      </Container>
    </Section>
  );
};

export default Hero;
