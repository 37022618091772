import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import emailValidator from 'email-validator';
import TextInput from '../../../../components/forms/TextInput';
import Button from '../../../../components/Button';
import { Title, BodyLarge } from '../../../../components/typography';


const Section = styled.section`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  padding: 0 6%; //move this later
`;

const Container = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;
  padding:0 4%;
`;

//remove this
const SubContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  align-items: center;
  text-align: center;

  /* @media only screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
  } */
`;

const Form = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 25px;


  @media only screen and (max-width: 768px) {
    width: 300px;
  }
`;

const LearnMore = () => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement | null>(null);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [emailValue, setEmailValue] = useState<string>('');

  React.useEffect(() => {
    setButtonDisabled(!emailValidator.validate(emailValue));
  }, [emailValue]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = {
        access_key: process.env.REACT_APP_WEB3_FORMS_API_KEY,
        email: emailValue,
      };

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Form submitted successfully');
        setEmailValue('');
      } else {
        alert('Error sending form');
      }
    } catch (error) {
      alert('Error sending form');
    }
  };

  return (
    <Section id="home-learn-more">
      <Container>
        <SubContainer>
          <Title>{t('home.want_to_learn_more.title')}</Title>

          <BodyLarge style={{ color: 'lightgray' }}>{t('home.want_to_learn_more.intro')}</BodyLarge>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            action={'https://api.web3forms.com/submit'}
            method={'POST'}
          >
            <TextInput
              type={'hidden'}
              name={'access_key'}
              value={process.env.REACT_APP_WEB3_FORMS_API_KEY}
            />

            <TextInput
              placeholder="add your email"
              name="email"
              type="email"
              onChange={(evt: any) => setEmailValue(evt.target.value)}
              value={emailValue}
            />
            <Button
              type="submit"
              disabled={isButtonDisabled}
              label="Apply to be a beta tester"
              onClick={()=>console.log('test')}
            ></Button>
          </Form>
        </SubContainer>
      </Container>
    </Section>
  );
};

export default LearnMore;
